.radius-8 {
    border-radius: 8px;
}

.card-hover:hover {
  background-color: #efefef !important;
}

/* .pdf-container{
  width: 100%;
  height: calc(100vH - 60px);
  overflow-y: auto;
} */
.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
.mr-05{
  margin-right: .5rem;
}

.rounded-pill {
    border-radius: 50rem!important;
}