.z-index-top {
  z-index: 20000;
}
.nowrap {
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 6px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d7d7d7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aba8a8;
}

.float {
  position: fixed;
  bottom: 15px;
  right: 15px;
  font-size: 11px;
  display: inline-flex;
  align-items: center;
  height: 40px;
  border-radius: 25px;
  background-color: #25d366;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  color: white;
  z-index: 663;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 10%);
  text-decoration: none;
  transition: 300;
}

.dropdown-menu-right{
      top: 5px !important;
        
        transform: translate3d(0px, 40px, 0px) !important;
}
.leftmin40 {
  left: -40px !important;
}
.leftunset{
  left: unset !important;
}
.dropdown-menu-right > .dropdown-item{
  padding: 0.5rem 1.5rem !important;
}
.SlideModal__footer{
  box-shadow: none !important;
  border-top: #ccc 1px solid;
  height: 56px;

}
.h-overflowAuto{
  height: calc(100vh - 133px) !important;
}

.SlideModal_footer_button{
  bottom: 0px !important;
  left: 0px !important;
  position: absolute !important;
  width: 100% !important;
  z-index: 2000 !important;
  background: #fff !important;
  height:65px !important;
}


.ember-view {
  position: relative;
  display: block;
  padding: 8px 0 8px 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #343b42;
  cursor: pointer;
  line-height: 1.38;
}

.ember-view .icon {
  padding-right: 0.8rem;
}

.ember-view-active {
  border-left: 3px solid #c52f00;
  font-weight: 600 !important;
  color: #c52f00!important;
  background: #ddd;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
}

.ember-view:hover {
  color: #000;
  background: #ddd;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
}

.z-index-top {
  z-index: 2000;
}

.cross-md {
  top: 11px;
  left: 0;
  position: absolute;
  font-size: 1.3rem !important;
}

.pointer {
  cursor: pointer;
}

.table {
  margin-bottom: 0 !important;
}

.table-fix>thead:first-child>tr:first-child>th:first-child {
  position: absolute;
  display: inline-block;
  background-color: #f2f4f7;
  width: 200px;
  border-right: 1px solid #e3ebf3;
  white-space: nowrap;
}

.table-fix>tbody>tr>td:first-child {
  position: absolute;
  display: inline-block;
  background-color: #fff;
  width: 200px;
  border-top: 1px solid #e3ebf3;
  border-right: 1px solid #e3ebf3;
  white-space: nowrap;
}

.table-fix>tfoot>tr:first-child>th:first-child {
  position: absolute;
  display: inline-block;
  background-color: #fff;
  width: 200px;
  border-top: 1px solid #e3ebf3;
  border-right: 1px solid #e3ebf3;
  white-space: nowrap;
}

.table-fix>thead:first-child>tr:first-child>th:nth-child(2) {
  padding-left: 225px !important;
}

.table-fix>tbody>tr>td:nth-child(2) {
  padding-left: 225px !important;
}

.table-fix>tbody>tr>td {
  white-space: nowrap;
}

.table-fix>tfoot>tr:first-child>th:nth-child(2) {
  padding-left: 225px !important;
}

.table-fix>thead>tr>th {
  white-space: nowrap;
  background-color: #f2f4f7;
}

.bg-f4 {
  background-color: #f2f4f7;
}

.card-sh4 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.SlideModal__title {
  padding-left: 45px !important;
  color: #000;
  margin: 0;
}

.SlideWrapper {
  position: fixed !important;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 665;
}

.login-form {
  width: 40%;
  height: 100vH;
  background-color: #fff;
}

.login-bg {
  width: 60%;
  height: 100vH;
  background-color: #f2f4f7;
}
@media (max-width: 992px) {
  .login-bg-form {
    width: 50%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .login-bg {
    width: 50%;
  }
  .login-form {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .login-form {
    width: 100%;
  }

  .login-bg {
    display: none;
  }
}
.px-1-2{
  padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
}
.fs-16px{
  font-size: 16px !important;
}
.fs-14px {
  font-size: 14px !important;
}
.fs-12px {
  font-size: 12px !important; 
}
.mb-025rem{
  margin-bottom: 0.25rem !important;
}
.mb-06rem{
  margin-bottom: 0.6rem !important;
}

.form-control-auth{
  height: 48px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}
.carousel-control-next-icon, .carousel-control-prev-icon{
  filter: contrast(0.1);
}
.custom-styles {
  --ReactInputVerificationCode-itemWidth: 3rem;
  --ReactInputVerificationCode-itemHeight: 3.5rem;
}
.ReactInputVerificationCode__container{
  width: 100% !important;
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: #262626;
  font-weight: 500;
}

.custom-styles .ReactInputVerificationCode__item,
.custom-styles .ReactInputVerificationCode__item.is-active {
  box-shadow: none;
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: #8b8b8b;
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: #d26300;
}
.ReactTags__remove {
  margin-left: 10px;
  border: 1px solid #d50000;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 5px;
  line-height: 19px;
  font-weight: 800;
  color: #d50000;
  cursor: pointer;
}