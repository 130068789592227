.form-control{
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.25 !important;
    padding: 0.83rem 1.2rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
    color: #3c3f41 !important;
    border: 1px solid #acafb3 !important;
    background-color: #fff !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;
}

.table th, .table td {
    padding: 0.7rem 1rem;
}

input[type=radio] {
    width: 17px;
    height: 17px;
}